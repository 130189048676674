import * as React from 'react'
import cn from 'classnames'

type InfoLineProps = {
  label: string
  value: React.ReactNode
  className?: string
  labelProps?: React.HTMLAttributes<HTMLHeadingElement>
}

export const InfoLine: React.VFC<InfoLineProps> = (props) => {
  const { label, value, className, labelProps } = props

  const { className: labelClassName, ...restLabelProps } = labelProps || {}

  return (
    <div className={cn('flex gap-6 items-center', className)}>
      <h6
        className={cn(
          'font-bold min-w-[7rem] text-gray-600 text-sm w-28',
          labelClassName
        )}
        {...restLabelProps}
      >
        {label}
      </h6>
      {typeof value === 'string' ? (
        <p className="mb-0 text-gray-900 truncate">{value}</p>
      ) : (
        value
      )}
    </div>
  )
}
