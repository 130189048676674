import { GlobalModal } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import styles from './mega-modal.module.css'
import { useId } from '@radix-ui/react-id'

type LocalModalProps = Omit<GlobalModal.RootProps, 'id'> &
  Required<Pick<GlobalModal.RootProps, 'open' | 'onOpenChange'>> & {
    id?: never
  }
type GlobalModalProps = GlobalModal.RootProps

export type RootProps = GlobalModalProps | LocalModalProps

type MegaModalContext = { rightPaneOpen: boolean; toggleRightPane: () => void }
export const MegaModalContext = React.createContext({} as MegaModalContext)
export const useMegaModal = () => React.useContext(MegaModalContext)

export const Root: React.VFC<RootProps> = (props) => {
  const defaultId = useId()
  const { children, className, id = defaultId, ...restProps } = props

  return (
    <GlobalModal.Root
      className={cn(
        'bg-white flex flex-col h-[90vh] max-w-[1200px] relative rounded-lg w-[90vw]',
        styles.root,
        className
      )}
      {...restProps}
      id={id}
      onInteractOutside={({ isOverlay }) => isOverlay}
    >
      <PageRoot>{children}</PageRoot>
    </GlobalModal.Root>
  )
}

export const PageRoot: React.VFC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  const [rightPaneOpen, setRightPaneOpen] = React.useState(true)
  const toggleRightPane = () => setRightPaneOpen((prev) => !prev)

  return (
    <MegaModalContext.Provider value={{ rightPaneOpen, toggleRightPane }}>
      <div className={cn('relative h-full overflow-hidden', className)}>
        {children}
      </div>
    </MegaModalContext.Provider>
  )
}
