import { Circle } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'

type SkillLevelIndicatorProps = {
  level: number
  maxLevel: number
}

export const SkillLevelIndicator: React.VFC<SkillLevelIndicatorProps> = (
  props
) => {
  {
    const { level, maxLevel } = props

    return (
      <div className="flex h-6 items-center">
        <div className="bg-gray-50 border-0 border-b border-t border-l border-gray-100 border-solid flex h-full items-center justify-center rounded-bl rounded-tl w-7">
          <p className="mb-0 text-gray-600 text-xs">L{level}</p>
        </div>
        <div className="border-0 border-b border-t border-r border-gray-100 border-solid flex gap-1 h-full items-center px-1.5 rounded-br rounded-tr">
          {[...Array(maxLevel)].map((_, index) => (
            <Circle
              className={cn(
                'h-2.5 w-2.5',
                index + 1 <= level ? 'text-theme-40' : 'text-gray-100'
              )}
              key={index}
              weight="fill"
            />
          ))}
        </div>
      </div>
    )
  }
}
